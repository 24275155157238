import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index.vue'),
    children:[
      {
        path:"AboutRef",
        component: () => import('@/views/home/components/AboutRefPage.vue'),
      },
      {
        path:"SolutionRef",
        component: () => import('@/views/home/components/SolutionRefPage.vue'),
      },
      {
        path:"NewsRef",
        component: () => import('@/views/home/components/NewsRefPage.vue'),
      }
    ]
  },
  {
    path: '/news',
    name: 'NewsPage',
    component: () => import('@/views/news/index.vue'),
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import('@/views/about/index.vue'),
  },
  {
    path: '/solution',
    name: 'SolutionPage',
    component: () => import('@/views/solution/index.vue'),
  },
  {
    path: '/product',
    name: 'ProductPage',
    component: () => import('@/views/product/index.vue'),
  },
  {
    path: '/support',
    name: 'SupportPage',
    component: () => import('@/views/support/index.vue'),
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: () => import('@/views/contact/index.vue'),
  },
  {
    path: '/research01',
    name: 'C64RSPage',
    component: () => import('@/views/solution/c64rs.vue'),
  },
  {
    path: '/research02',
    name: 'X8Page',
    component: () => import('@/views/solution/x8.vue'),
  },
  {
    path: '/research03',
    name: 'AR4Page',
    component: () => import('@/views/solution/ar4.vue'),
  },
  {
    path: '/research32',
    name: 'A32Page',
    component: () => import('@/views/solution/a32.vue'),
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: () => import('@/views/solution/analysis.vue'),
  },
  {
    path: '/operation',
    name: 'Operation',
    component: () => import('@/views/solution/operation.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = '上海全澜科技有限公司';
  next();
});

export default router;