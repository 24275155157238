<template>
    <div class="footer">
        <div class="nav-link">
            <div class="footer-logo">
                <a href="/"><img src="@/assets/images/logo2@2x.png"/></a>
            </div>
            <div class="footer-phone">
                <div class="wrap">
                    <img src="@/assets/images/phone.png"/>
                    <span>+8619101672569</span>
                </div>
            </div>
            <div class="footer-email">
                <div class="wrap">
                    <img src="@/assets/images/email.png"/>
                    <span>sales@eegion.com</span>
                </div>
            </div>
            <div class="footer-address">
                <div class="wrap">
                    <img src="@/assets/images/address.png"/>
                    <span>上海市松江区强业路801号
                    G60佘山智造园4-3号楼</span>
                </div>
            </div>
            <div class="footer-qr">
                <img src="@/assets/images/qr.png"/>
                <p class="p1">扫描二维码</p>
                <p>关注官方公众号</p>
            </div>
            <div class="footer-nav">
                <ul>
                    <li>
                        <ul>
                            <li class="nav-item"><a href="/">首页</a></li>
                            <li class="nav-item"><a href="/about">关于全澜</a></li>
                            <!-- <li class="nav-item"><router-link to="/news">新闻中心</router-link></li> -->
                            <li class="nav-item">
                                <a href="/solution">产品与服务</a>
                                <!-- <div class="nav-solution">
                                    <div class="item" @click="forwardTo('/research01')">澜鲸高精度多通道系列</div>
                                    <div class="item">
                                    澜猫多模态无线系列
                                    <div class="sub-nav">
                                        <div class="item" @click="forwardTo('/research02')">居家睡眠监测与调控</div>
                                        <div class="item" @click="forwardTo('/research03')">小动物多模态无线电生理监测</div>
                                        <div class="item" @click="forwardTo('/research32')">大动物用无线多通道电刺激-电生理监测</div>
                                    </div>
                                    </div>
                                    <div class="item">
                                    数据分析与手术服务
                                    <div class="sub-nav">
                                        <div class="item" @click="forwardTo('/analysis')">数据分析服务</div>
                                        <div class="item" @click="forwardTo('/operation')">手术与手术培训服务</div>
                                    </div>
                                    </div>
                                </div> -->
                            </li>
                            <!-- <li class="nav-item"><router-link to="/product">产品中心</router-link></li> -->
                            <li class="nav-item"><a href="/support">服务支持</a></li>
                            <li class="nav-item"><a href="/contact">联系我们</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="split-line" style="margin-top: 50px;"></div>
        <div class="footer-bottom">
            <span>备案号：<a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2022031292号-3</a></span>
            
        </div>
    </div>
</template>

<script>

  import { defineComponent } from 'vue';

  export default defineComponent({
    methods: {
      forwardTo(url) {
        window.location.href = url;
      }
    },
  });
</script>
<style  scoped  lang="scss">

    .footer {
        width: 100vw;
        height: 340px;
        min-height: 340px;
        background-color: #3266B8 ;

        .nav-link {            
            position: relative;    
            margin: 0px calc(50% - 600px); 
            padding-top: 50px;
            width: 1200px;
            min-width: 1200px; 
            height: 232px;  
            
            :hover {
                background-color: transparent;
            }

            .footer-logo {
                position: absolute;
                width: 138px;
                height: 46px;
                margin: 0px;
            }
            .footer-phone {
                position: absolute;
                width: 138px;
                height: 32px;
                margin: 0px;
                margin-top: 75px;
                
                span {
                    font-family: DINCond, DINCond;
                    font-weight: bold;
                    font-size: 28px;
                    color: #FFFFFF;
                    line-height: 32px;
                }

                img {
                    margin-top: 5px;
                }
            }
            .footer-email {
                position: absolute;
                width: 138px;
                height: 32px;
                margin: 0px;
                margin-top: 122px;

                span {                    
                    font-weight: 500;
                    font-size: 16px;
                    color: rgba(255,255,255,0.6);
                    line-height: 22px;
                }
                
            }
            .footer-address {
                position: absolute;
                width: 230px;
                height: 32px;
                margin: 0px;
                margin-top: 160px;

                span {                    
                    font-weight: 500;
                    font-size: 16px;
                    color: rgba(255,255,255,0.6);
                    line-height: 22px;
                }

                img {
                    margin-top: 10px;
                }
            }

            .footer-phone .wrap, .footer-email .wrap, .footer-address .wrap {
                position: relative;
                width: 100%;
                height: 100%;
                
                img {
                    position: absolute;
                    left: 0px;
                    top: 0px;
                }

                span {
                    position: absolute;
                    left: 40px;
                    top: 0px;
                    font-family: PingFangSC, PingFang SC;
                    text-align: left;
                }
            }

            .footer-nav {
                position: absolute;
                flex: auto;
                width: 800px;
                height: 160px;
                margin-left: 250px;
                

                /* 为 ul 应用样式，使其成为块级元素，并清除默认的列表样式 */
                ul {
                    width: 100%;
                    align-items: center;
                    list-style: none; /* 移除列表的默认标记 */
                    padding: 0; /* 移除默认的内边距 */
                    margin: 0; /* 移除默认的外边距 */
                    display: flex; /* 使用 Flexbox 布局 */
                    justify-content: space-between;

                    li {
                        flex: 1;
                        text-align: center;
                        // display: inline-block; /* 使 li 元素成为内联块级元素 */
                        // margin-right: 10px; /* 为 li 元素添加右边距，可根据需要调整 */
                        a {    
                            text-wrap: nowrap;
                            font-weight: 400;
                            list-style-type: disc;
                            text-decoration: none;
                            color:#ffffff;
                        }
                    }

                    .nav-item {                        
                        line-height: 36px;
                    }
                }            
            }

            .footer-qr {
                position: absolute;
                width: 98px;
                height: 158px;
                margin-top: 4px;
                margin-left: 1102px;

                p {
                    font-weight: 500;
                    font-size: 14px;
                    color: rgba(255,255,255,0.6);
                    line-height: 20px;
                    text-align: center;
                    margin: 0px;
                }

                .p1 {
                    margin-top: 20px;
                }
            }
        }
    }


    .footer-qr img, .footer-logo img {
        max-width: 100%;
        max-height: 100%;
    }

    .footer-bottom {
        width:100%; 
        bottom:0px; 
        height:60px; 
        padding: 10 120px; 
        background-color: transparent;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: rgba(255,255,255,0.6);
        line-height: 60px;
    }
    .footer-bottom span {
        color: white;
        height: 60px;
        text-align: center;
    }
    .footer-bottom a {
        color: white;
        opacity: 0.8;
    }
</style>